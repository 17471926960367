import React from "react";

import i200w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_200.gif";
import i374w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_374.gif";
import i498w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_498.gif";
import i603w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_603.gif";
import i690w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_690.gif";
import i759w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_759.gif";
import i829w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_829.gif";
// import i891w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_891.gif";
import i950w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_950.gif";
import i1005w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1005.gif";
import i1055w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1055.gif";
import i1087w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1087.gif";
import i1147w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1147.gif";
import i1196w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1196.gif";
import i1238w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1238.gif";
import i1286w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1286.gif";
// import i1318w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1318.gif";
import i1347w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1347.gif";
import i1371w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1371.gif";
import i1400w from "./assets/webWhite-moth-on-black-screen_h7yjnx_c_scale,w_1400.gif";

import style from "./Home.module.css";

export default function Home() {
  return (
    <div className={style.ImageWrapper}>
      <img
        className={style.Image}
        sizes="(max-width: 1400px) 100vw, 1400px"
        srcSet={`${i200w} 200w, ${i374w} 374w, ${i498w} 498w, ${i603w} 603w, ${i690w} 690w, ${i759w} 759w, ${i829w} 829w, ${i950w} 950w, ${i1005w} 1005w, ${i1055w} 1055w, ${i1087w} 1087w, ${i1147w} 1147w, ${i1196w} 1196w, ${i1238w} 1238w, ${i1286w} 1286w, ${i1347w} 1347w, ${i1371w} 1371w, ${i1400w} 1400w`}
        src={i1400w}
        alt="Chiara Avagliano"
      ></img>
    </div>
  );
}
