import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import Info from "./Info";

import style from "./App.module.css";

function App() {
  return (
    <Router>
      <div className={style.App}>
        <Route component={Header} />
        <Route path="/" exact component={Home} />
        <Route path="/info" exact component={Info} />
        <Route component={Footer} />
      </div>
    </Router>
  );
}

export default App;
