import React from "react";
import { Link } from "react-router-dom";

import style from "./Header.module.css";

export default function Header({ location }) {
  const { pathname } = location;

  return (
    <div className={style.Header}>
      <span>
        {pathname !== "/" ? (
          <Link to="/">home</Link>
        ) : (
          <Link to="/info">info</Link>
        )}
        ,{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.instagram.com/chiaravagliano/"
        >
          Instagram
        </a>
      </span>
    </div>
  );
}
